<template>
  <div class="row">
    <div
      class="col-6 col-md-4 mb-md-45"
      v-for="product of productsList"
      :key="product.id"
    >
      <ProductCard :product="product" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ProductCard from "@/esf_lommel_dt1_europe/core/components//ProductCard";
export default {
  name: "ProductsSection",
  components: { ProductCard },
  props: {
    productsList: {
      type: Array,
      require: true,
    },
  },
  methods: mapActions(["setProductStatus"]),
};
</script>
