<template>
  <div class="banner" :style="{ backgroundImage: `url(${bannerBg})` }">
    <span class="d-block banner-heading">Nieuwe collectie 2020/2021</span>
    <span class="d-block banner-txt"
      >Wij hebben talloze nieuwe producten van A-merken aan het assortiment
      toegevoegd!</span
    >
    <div class="btn-holder">
      <b-link class="btn banner-btn">Nu bekijken</b-link>
    </div>
  </div>
</template>

<script>
//import bannerImage from '"@/esf_lommel_dt1_europe/assets/advertisementBanner01.jpg'
import bannerImage from "@/esf_lommel_dt1_europe/assets/DT1Pics/CollectieFoto.jpg";
export default {
  name: "AdvertisingBanner",
  data: () => ({
    bannerBg: bannerImage,
  }),
};
</script>

<style lang="scss" scoped>
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
.banner {
  background-size: cover;
  background-position: 88% 0;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 250px;
  padding: 10px 155px 20px 22px;
  line-height: 1.2;

  @include media("screen", ">=tablet") {
    min-height: 300px;
    padding: 27px;
    line-height: 1.6;
  }

  a {
    color: white;

    &:hover {
      color: white !important;
      background: #000;
      border-color: #000;
    }
  }
}

.banner-heading {
  font-size: 30px;
  letter-spacing: -0.025em;
  font-weight: 500;
  margin-bottom: 10px;

  @include media("screen", ">=tablet") {
    margin-bottom: 0;
  }
}

.banner-txt {
  font-size: 16px;
  letter-spacing: -0.025em;
  padding-left: 3px;
  margin-bottom: 18px;
}

.btn {
  font-size: 18px;
  font-weight: normal;
  display: inline-block;
  padding: 7px 10px;
  border: 1px solid white;
  min-width: 150px;
}
</style>
